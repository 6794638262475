
















































































































import {
  defineComponent, onMounted, computed, PropType, ref, useContext
} from '@nuxtjs/composition-api';
import {
  SfLink,
  SfButton,
  SfList,
  SfInput,
  SfImage,
  SfIcon,
  SkeletonLoader,
} from '~/components';
import SfBottomModal from '~/components/molecules/SfBottomModal/SfBottomModal.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { StoreConfig } from '~/modules/GraphQL/types';
import {AvailableStores, useStore, useCurrency } from '~/composables';
import { useCountriesStore } from "~/modules/countries/stores/countries";
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'StoresModal',
  components: {
    SfButton,
    SfLink,
    SfList,
    SfBottomModal,
    SfInput,
    SfIcon,
    SfImage,
    LoadWhenVisible,
    SkeletonLoader,
  },
  props: {
    isLangModalOpen: Boolean,
    storeConfig: {
      type: Object as PropType<StoreConfig>,
      default: (): StoreConfig => ({}),
    }
  },
  emits: ['closeModal'],
  setup(props, context) {
    const {
      stores,
      change: changeStore,
      load: loadStores,
    } = useStore();
    const { app } = useContext();
    const {
      currency: currencies,
    } = useCurrency();
    const { countriesList } = storeToRefs(useCountriesStore());
    const shipToCountry = ref();
    const selectedCountryIconUrl= ref();

    const filterValue =  ref('');

    const handledCountriesList = computed(() => !countriesList.value?.items?.length
      ? []
      : countriesList.value.items.map(item => {
        if(item.code === shipToCountry.value) {
          item.position = 0;
          item.is_primary = true;
        }
        return item;
      })
      .sort((countryCur, countryNext) => countryCur.is_primary && countryNext.is_primary ? 0 : countryCur.is_primary && !countryNext.is_primary ? -1 : 1 )
      .filter(country => country.name.toLowerCase().includes(filterValue.value.toLowerCase()))
      .sort((countryCur, countryNext) => (countryCur.name.toLowerCase().indexOf(filterValue.value.toLowerCase()) < countryNext.name.toLowerCase().indexOf(filterValue.value.toLowerCase()) ? -1 : 1)))

    const setShipToCountry = (country) => {
      context.emit('closeModal');
      const countryUrl = new URL(country.url);
      if (country.currency_code) countryUrl.searchParams.append('currency', country.currency_code);

      window.location.assign(countryUrl.toString());
    };

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    onMounted(() => {
      shipToCountry.value = app.$cookies.get('vsf-selected-country')  || '';
      selectedCountryIconUrl.value = app.$cookies.get('vsf-selected-country-icon')  || '';

      if (stores.value && stores.value?.length) return;
      loadStores();
    });

    return {
      countriesList,
      availableStores,
      changeStore,
      loadStores,
      handledCountriesList,
      shipToCountry,
      selectedCountryIconUrl,
      setShipToCountry,
      filterValue,
      currencies,
    };
  }
});
